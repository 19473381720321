<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" :class="{'modal-container-mobile': isMobile}">
          <div class="modal-header">
            <h5 class="modal-title" id="quote_modal_title">Password forgotten</h5>
            <button type="button" class="close" @click="$emit('close')"><span aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body">
            <div class="col-12" v-if="!sent">
              <center class="montserrat">
                <label>Please enter your username and email address</label>
                <input type="text" class="form-control" :disabled="sending" v-model="username" placeholder="username"/>
                <input type="text" class="form-control" :disabled="sending" v-model="email" placeholder="email"/>
              </center>
            </div>
            <div class="col-12" v-if="sent && success">
              <center class="montserrat">
                <label>An email has been sent to {{email}} with a link to reset your password</label>
              </center>
            </div>
            <div class="col-12" v-if="sent && !success">
              <center class="montserrat">
                <label>{{message}}</label>
              </center>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-6 text-center" :class="{'p-0 m-0 pr-1': isMobile}">
              <button type="button" class="btn button-light-b font4" :class="{'btn-mobile': isMobile}" v-if="!sent" :disabled="!ready || sending" @click="send">Send<span v-if="sending">ing</span></button>
            </div>
            <div class="col-6 text-center" :class="{'p-0 m-0 pl-1': isMobile}">
              <button type="button" class="btn button-light-b font4" :class="{'btn-mobile': isMobile}" v-if="!sent && !sending" @click="$emit('close')">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    usrname: {
      type: String,
      required: false,
      default: () => null
    }
  },
  data () {
    return {
      username: null,
      email: null,
      message: null,
      sending: false,
      sent: false,
      success: false
    }
  },
  computed: {
    ...mapGetters([
      'isMobile'
    ]),
    ready () {
      return this.username && this.email
    }
  },
  created () {
    this.username = this.usrname
  },
  methods: {
    ...mapActions([
      'passwordForgotten'
    ]),
    send () {
      this.sending = true
      this.passwordForgotten({
        username: this.username,
        email: this.email
      }).then(res => {
        this.sending = false
        this.sent = true
        this.success = res.success
        this.message = res.message
        setTimeout(() => {
          if (this.success) {
            this.$emit('close')
          } else {
            this.sent = false
          }
        }, 5000)
      }).catch(err => {
        this.sending = false
        this.sent = true
        this.success = false
        this.message = err
        setTimeout(() => {
          this.message = null
          this.sent = false
        }, 5000)
      })
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-title {
  color: black;
}
.modal-container {
  width: 600px;
  margin: 0px auto;
/*   padding: 20px 30px; */
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
}

.modal-container-mobile {
  width: 100%;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

.btn {
  width: 70%;
}

.btn-mobile {
  width: 90%;
}
</style>
